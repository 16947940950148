.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main > *:first-child {
  margin-top: 20px;
}

.container {
  margin-top: 40px;
  width: 450px;
  display: flex;
  flex-direction: column;
}
