.container {
  /* position: fixed; */
  top: 0px;
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  margin-left: 50px;
  z-index: 100;
}
/* 
.logoImage {
  padding: 1rem;
  margin-left: 5rem;
} */
