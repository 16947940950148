.dots-5 {
  width: 12px;
  height: 12px;
  margin: auto;
  background: var(--color-black);
  border-radius: 50%;
  box-shadow: 20px 0 #00000022, -20px 0  var(--color-black);
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 20px 0  var(--color-black), -20px 0 #00000022;
    background:  var(--color-black);
  }
  33% {
    box-shadow: 20px 0  var(--color-black), -20px 0 #00000022;
    background: #00000022;
  }
  66% {
    box-shadow: 20px 0  var(--color-black), -20px 0 var(--color-black);
    background: #00000022;
  }
}
