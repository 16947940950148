.button {
  border-radius: var(--border-radius);
  border: none;
  box-shadow: 0px 2px 7px 0px var(--button-shadow-color);
  color: var(--color-text-black);
  font-family: inherit;
  font-size: 2.2rem;
  font-weight: bold;
  height: 7.2rem;
  outline: none;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  font-family: (--secondary-font);
}

.withBackground {
  background: var(--button-gradient);
}

.noBackground {
  background-color: transparent;
  border: 4px solid var(--theme-color2);
}

.grayBackground {
  background-color: var(--light-gray-color);
}

.gredientBackground {
  background: var(--button-gradient);
}
