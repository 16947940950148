.logoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logoContainer h5 {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
